import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProfile {


  /*   constructor ({ url }) {
        console.log ( url );
        httpOk = axios.create({
        baseURL: url,
         headers: { 'Content-Type': 'application/json' }
      })
    } */


  getProfile(username, tokensystem) {
    //var resource = "/getWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);


    let payload = "user=" + username + "&password=" + tokensystem + `&timestamp=${new Date().getTime()}`;

    return axios.post(`https://services.ebadge.it/public/api/getWorkerInfoWithoutResAlertMsg?${payload}`, formData, { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' });

  }



  setProfile(username, tokensystem, profile) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("date", new Date().getTime());

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    //return axios.post( `https://services.ebadge.it/public/api/setWorkerInfo` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //return axios.post( `http://services.ebadge.local/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    return axios.post(`https://services.ebadge.it/public/api/setWorkerInfoWithoutRes`, formData, { 'Content-Type': 'multipart/form-data' });

    //    return httpOk.post(resource, formData   ) ;
  }



  savePassword(username, tokensystem, profile) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post(`https://app.emtool.eu/public/api/emt/setWorkerPassword`, formData, { 'Content-Type': 'multipart/form-data' });

    //return axios.post( `http://emtool.local/public/api/emt/setWorkerPassword` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //    return httpOk.post(resource, formData   ) ;
  }

  getUrlDomainNew(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  savePasswordNew(username, tokensystem, profile, v_token) {
    // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append("user", username);
    formData.append("password", tokensystem);
    formData.append("v_token", v_token);

    for (var key in profile) {
      // console.log (key,profile[key] );
      formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post(this.getUrlDomainNew("savePwd"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIProfile({
  url: "https://services.ebadge.it/public/api/"
})
