<template>
    <v-container id="conf_send_mail" class="w-container v-overflow confInvioMailAlert"
        style="overflow-x:hidden;padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #45b7e9;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">
                    <v-checkbox label="Invio mail Pagamenti Scaduti"
                        v-model="impostazioni_invio_mail.mail_enable_minutes" true-value="S" false-value="N"
                        @click="chkChange()" id="chkMinutes" class="cl-checkbox"></v-checkbox>
                </v-col>

            </v-row>


            <v-row style="margin-left:1px!important" v-if="showRow">

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="6" md="6">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                        (H)</label>

                    <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenza" class='selfrequenza' display-format="H"
                        cancel-Text="" done-Text="" value="" interface="action-sheet"
                        style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                        min="2022-03-29T01:21:23.654+02:00">

                    </ion-datetime>

                </v-col>

            </v-row>


            <v-row style="margin-left:-8px!important" v-if="showRow">

                <v-col cols="2" md="2">

                    <label style="margin-left:11px">Lun</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mar</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mer</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Gio</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Ven</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Sab</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:8px">Dom</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox"></v-checkbox>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <v-checkbox label="Invio Mail Pagamenti In Scadenza"
                        v-model="impostazioni_invio_mail.mail_att_expiring" true-value="S" false-value="N"
                        @click="chkChangeExpired()" id="chkMinutesExpiring" class="cl-checkbox"></v-checkbox>
                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow2">

                <v-col cols="6" md="6">

                    <label>Giorni Alla Scadenza</label>

                    <div id="d_fido_ant_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="impostazioni_invio_mail.days_left_payments_expiring"
                                        :options="'integer'" ref="fldDaysLeft" id="fldDaysLeft"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow2">

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                        display-format="HH:mm"></ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                        display-format="HH:mm"></ion-datetime>

                </v-col>

                <v-col cols="6" md="6">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                        (H)</label>

                    <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring" class='selfrequenzaexpiring'
                        display-format="H" cancel-Text="" done-Text="" value="" interface="action-sheet"
                        style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                        min="2022-03-29T01:21:23.654+02:00">

                    </ion-datetime>

                </v-col>

            </v-row>

            <v-row style="margin-left:-8px!important" v-if="showRow2">

                <v-col cols="2" md="2">

                    <label style="margin-left:11px">Lun</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mar</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mer</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Gio</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Ven</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Sab</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:8px">Dom</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                        false-value="N" class="cl-checkbox"></v-checkbox>

                </v-col>

            </v-row>

        </v-form>

    </v-container>
</template>

<script>
import apiconfinviomail from "../utils/confinviomail/apiconfinviomail";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/

        this.setupButtons();

        setTimeout(() => {
            this.syncConfInvioMail();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    setTimeout(() => {
                        this.validate();
                    }, 200);

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        fieldDis: false,

        showRow: true,
        showRow2: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Configurazione Invio Mail",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni_invio_mail: {
            mail_enable_daily: "N",
            mail_enable_weekly: "N"
        },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('al_info_global')).aziende,

        tipologie: JSON.parse(localStorage.getItem('al_info_global')).tipologie_pagamento,

        stati_pagamento: JSON.parse(localStorage.getItem('al_info_global')).stati_pagamento

    }),

    methods: {

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-alertduedate.png",
                    //class: "inverted",
                    title: "Salva",
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;

            if ($("#selfrequenza").val() != "") {

                var v_date = new Date($("#selfrequenza").val());

                var v_minutes = v_date.getHours() * 60;

                that.impostazioni_invio_mail.mail_minutes_tot = v_minutes;

            }

            var fromTime = null;
            if ($("#dalle").val() != "") {

                if ($("#dalle").val().length > 8) {

                    fromTime = $("#dalle").val().toString().substring(11, 16);
                    fromTime = fromTime + ":00";
                    that.impostazioni_invio_mail.mail_minutes_form_time = fromTime;

                } else {

                    that.impostazioni_invio_mail.mail_minutes_form_time = $("#dalle").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_minutes_form_time = null;
            }

            var toTime = null;
            if ($("#alle").val() != "") {

                if ($("#alle").val().length > 8) {

                    toTime = $("#alle").val().toString().substring(11, 16);
                    toTime = toTime + ":00";
                    that.impostazioni_invio_mail.mail_minutes_to_time = toTime;

                } else {

                    that.impostazioni_invio_mail.mail_minutes_to_time = $("#alle").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_minutes_to_time = null;
            }

            // that.impostazioni_invio_mail.mail_minutes_form_time = $("#dalle").val();
            // that.impostazioni_invio_mail.mail_minutes_to_time = $("#alle").val();

            if ($("#selfrequenzaexpiring").val() != "") {

                var v_date_exp = new Date($("#selfrequenzaexpiring").val());

                var v_minutes_exp = v_date_exp.getHours() * 60;

                that.impostazioni_invio_mail.mail_att_expiring_minute = v_minutes_exp;

            }

            var fromExp = null;
            if ($("#dalleExpiring").val() != "") {

                if ($("#dalleExpiring").val().length > 8) {

                    fromExp = $("#dalleExpiring").val().toString().substring(11, 16);
                    fromExp = fromExp + ":00";
                    that.impostazioni_invio_mail.mail_att_expiring_time_from = fromExp;

                } else {

                    that.impostazioni_invio_mail.mail_att_expiring_time_from = $("#dalleExpiring").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_att_expiring_time_from = null;
            }

            var toExp = null;
            if ($("#alleExpiring").val() != "") {

                if ($("#alleExpiring").val().length > 8) {

                    toExp = $("#alleExpiring").val().toString().substring(11, 16);
                    toExp = toExp + ":00";
                    that.impostazioni_invio_mail.mail_att_expiring_time_to = toExp;

                } else {

                    that.impostazioni_invio_mail.mail_att_expiring_time_to = $("#alleExpiring").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_att_expiring_time_to = null;
            }

            // validazione pagamenti scaduti
            if (that.impostazioni_invio_mail.mail_enable_minutes == "S") {

                if (that.impostazioni_invio_mail.mail_minutes_tot == "") {
                    errore = "Specificare la frequenza dei pagamenti scaduti";
                } else {
                    if (that.impostazioni_invio_mail.mail_minutes_tot == null) {
                        errore = "Specificare la frequenza dei pagamenti scaduti";
                    }
                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_form_time == "") {
                        errore = "Specificare un valore dalle ore dei pagamenti scaduti";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_form_time == null) {
                            errore = "Specificare un valore dalle ore dei pagamenti scaduti";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_to_time == "") {
                        errore = "Specificare un valore alle ore dei pagamenti scaduti";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_to_time == null) {
                            errore = "Specificare un valore alle ore dei pagamenti scaduti";
                        }
                    }

                }

            }

            // pagamenti in scadenza
            if (that.impostazioni_invio_mail.mail_att_expiring == "S") {

                if (that.impostazioni_invio_mail.mail_att_expiring_minute == "") {
                    errore = "Specificare la frequenza dei pagamenti in scadenza";
                } else {
                    if (that.impostazioni_invio_mail.mail_att_expiring_minute == null) {
                        errore = "Specificare la frequenza dei pagamenti in scadenza";
                    }
                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_att_expiring_time_from == "") {
                        errore = "Specificare un valore dalle ore dei pagamenti in scadenza";
                    } else {
                        if (that.impostazioni_invio_mail.mail_att_expiring_time_from == null) {
                            errore = "Specificare un valore dalle ore dei pagamenti in scadenza";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_att_expiring_time_to == "") {
                        errore = "Specificare un valore alle ore dei pagamenti in scadenza";
                    } else {
                        if (that.impostazioni_invio_mail.mail_att_expiring_time_to == null) {
                            errore = "Specificare un valore alle ore dei pagamenti in scadenza";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.days_left_payments_expiring > 50) {
                        errore = "Specificare un valore dei giorni rimasti alla scdenza inferiore o uguale a 50";
                    }

                }


            }

            console.log("CONF: ", that.impostazioni_invio_mail);

            if (errore == "") {

                console.log("ok");

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("TOKEN ", v_token);
            console.log("THAT ", that);

            console.log("IMPOST: ", that.impostazioni_invio_mail);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfinviomail.setConfInvioMail(
                that.impostazioni_invio_mail,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setConfInvioMail", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncConfInvioMail: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfinviomail.getConfInvioMail(
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfInvioMail", res);

                try {

                    if (res.data.Result != null) {

                        this.impostazioni_invio_mail = res.data.Result;

                        if (res.data.Result.mail_minutes_form_time != null) {

                            $("#dalle").val(res.data.Result.mail_minutes_form_time);

                        }

                        if (res.data.Result.mail_minutes_to_time != null) {

                            $("#alle").val(res.data.Result.mail_minutes_to_time);

                        }

                        if (res.data.Result.mail_minutes_tot != null) {

                            var v_valore = res.data.Result.mail_minutes_tot / 60;

                            var strDay = v_valore.toString().padStart(2, "0");

                            console.log("DAY: ", strDay);

                            var v_data = "2022-03-29T" + strDay + ":21:23.654+02:00";

                            $("#selfrequenza").val(v_data);

                        }

                        if (res.data.Result.mail_att_expiring_time_from != null) {

                            $("#dalleExpiring").val(res.data.Result.mail_att_expiring_time_from);

                        }

                        if (res.data.Result.mail_att_expiring_time_to != null) {

                            $("#alleExpiring").val(res.data.Result.mail_att_expiring_time_to);

                        }

                        if (res.data.Result.mail_att_expiring_minute != null) {

                            v_valore = res.data.Result.mail_att_expiring_minute / 60;

                            strDay = v_valore.toString().padStart(2, "0");

                            console.log("DAY: ", strDay);

                            v_data = "2022-03-29T" + strDay + ":21:23.654+02:00";

                            $("#selfrequenzaexpiring").val(v_data);

                        }

                        if (this.impostazioni_invio_mail.mail_enable_minutes == "N") {

                            this.fieldDis = true;
                            this.showRow = false;

                        }

                        if (this.impostazioni_invio_mail.mail_att_expiring == "N") {

                            this.showRow2 = false;

                        }

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png") !important;
    background-size: 34px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png") !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

#fldDaysLeft {
    margin-left: -10px;
}

.confInvioMailAlert .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    margin-left: -10px;
}




.confInvioMailAlert .cl-checkbox i {
    font-size: 13px !important;
}


.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}



.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
