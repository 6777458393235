import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITipologiePagamento {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/alertduedate/" + nome_metodo;

  }

  async setTipologiaPagamento(tipologia_pagamento, id, user, file)
  {

   // console.log ("setTipologiaPagamento file" , file[0] );

    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);
    formData.append("file" ,  file);

    for ( var key in tipologia_pagamento ) {
       formData.append(key, tipologia_pagamento[key]);
    }

    return axios.post(this.getUrlDomain("setTipologiaPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getTipologiaPagamento(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("getTipologiaPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteTipologiaPagamento(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("deleteTipologiaPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteMultiTipologiaPagamento(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("deleteMultiTipologiaPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }






}

export default new APITipologiePagamento({
  url: "http://emtool.local/public/api/alertduedate/"
})
