<template>
    <v-container id="conf_send_mail" class="w-container v-overflow confInvioMailAlert"
        style="overflow-x:hidden;padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #45b7e9;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="9" md="9">
                    <div id="cont_tipologiaid" style="cursor:pointer">
                        <ion-item id="itemTipologia"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipologia </ion-label>

                            <ion-select id="id_tipologia" name="id_tipologia" class='id_tipologia' cancel-Text="Chiudi"
                                done-Text="" :value="impostazioni_invio_mail.id_tipologia" interface="action-sheet">

                                <ion-select-option v-for="item in tipologie" :key="item.id" :value='item.id'>
                                    {{ item.nome }} ({{ item.descrizione }})</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow4">

                <v-col cols="6" md="6">
                    Fatture Scadute
                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow3">

                <v-col cols="6" md="6">

                    <label style="font-size:11px">Giorni Alla Scadenza</label>

                    <div id="d_fido_ant_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                        :options="'integer'" ref="fldDaysLeft" id="fldDaysLeft"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row style="margin-left:1px!important" v-if="showRow12">

                <v-col cols="6" md="6">

                    <label style="font-size:11px">Giorni A Fine Mese</label>

                    <div id="d_fido_ant_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                        :options="'integer'" ref="fldDaysLeft" id="fldDaysLeft"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row style="margin-left:1px!important" v-if="showRow">

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="6" md="6">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                        (H)</label>

                    <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenza" class='selfrequenza' display-format="H"
                        cancel-Text="" done-Text="" value="" interface="action-sheet"
                        style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                        min="2022-03-29T01:21:23.654+02:00">

                    </ion-datetime>

                </v-col>

            </v-row>


            <v-row style="margin-left:-8px!important" v-if="showRow">

                <v-col cols="2" md="2">

                    <label style="margin-left:11px">Lun</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mar</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mer</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Gio</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Ven</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Sab</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:8px">Dom</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow2">

                <v-col cols="6" md="6">

                    <label>Numero files rimanenti nella folder</label>

                    <div id="d_fido_ant_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="impostazioni_invio_mail.num_files_left"
                                        :options="'integer'" ref="fldNumFilesLeft"
                                        id="fldNumFilesLeft"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>



            <v-row style="margin-left:1px!important" v-if="showRow5">

                <v-col cols="6" md="6">
                    Fatture In Scadenza
                </v-col>

            </v-row>

            <v-row style="margin-left:1px!important" v-if="showRow6">

                <v-col cols="6" md="6">

                    <label style="font-size:11px">Giorni Alla Scadenza</label>

                    <div id="d_fido_ant_popup" style="cursor:pointer"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="impostazioni_invio_mail.days_before_alert"
                                        :options="'integer'" ref="fldDaysLeft" id="fldDaysLeft"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row style="margin-left:1px!important" v-if="showRow7">

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="dalleExpiring" name="dalleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                        display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="alleExpiring" name="alleExpiring" class='hourbooking' cancel-Text="" done-Text=""
                        display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="6" md="6">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq.
                        (H)</label>

                    <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenzaexpiring" class='selfrequenzaexpiring'
                        display-format="H" cancel-Text="" done-Text="" value="" interface="action-sheet"
                        style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important"
                        min="2022-03-29T01:21:23.654+02:00">

                    </ion-datetime>

                </v-col>

            </v-row>

            <v-row style="margin-left:-8px!important" v-if="showRow8">

                <v-col cols="2" md="2">

                    <label style="margin-left:11px">Lun</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_monday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mar</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_tuesday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mer</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_wednesday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Gio</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_thursday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Ven</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_friday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Sab</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_saturday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:8px">Dom</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_att_expiring_sunday" true-value="S"
                        false-value="N" :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

            </v-row>


            <v-row style="margin-left:1px!important" v-if="showRow10">

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                    <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55"
                        style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px"
                        id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm">
                    </ion-datetime>

                </v-col>

                <v-col cols="3" md="3">

                    <div id="cont_minutifreq" style="cursor:pointer">
                        <ion-item id="itemFreq"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Freq.
                            </ion-label>

                            <ion-select id="minfreq" name="minfreq" class='minfreq' cancel-Text="Chiudi" done-Text=""
                                :value="impostazioni_invio_mail.mail_minutes_tot" interface="action-sheet">

                                <ion-select-option v-for="item in minuti_frequenza" :key="item.valore"
                                    :value='item.valore'> {{ item.descrizione }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>



            <v-row style="margin-left:-8px!important" v-if="showRow11">

                <v-col cols="2" md="2">

                    <label style="margin-left:11px">Lun</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mar</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Mer</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Gio</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Ven</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:11px">Sab</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

                <v-col cols="2" md="2" class="cl_combo1">

                    <label style="margin-left:8px">Dom</label>
                    <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S" false-value="N"
                        :disabled="fieldDis" class="cl-checkbox" @click="chkChange"></v-checkbox>

                </v-col>

            </v-row>


            <v-row v-if="showRowTitle">

                <v-col cols="9" md="9">

                    <v-text-field v-model="impostazioni_invio_mail.titolo" label="Titolo" id="fldTitolo"></v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="showRowDesc">

                <v-col cols="9" md="9">

                    <v-textarea label="Descrizione" v-model="impostazioni_invio_mail.descrizione"
                        id="fldDescrizione"></v-textarea>

                </v-col>

            </v-row>





            <v-row v-if="showRowMail">

                <v-col cols="12" md="12">
                    <v-checkbox label="Invio Mail" v-model="impostazioni_invio_mail.send_mail" true-value="S"
                        false-value="N" id="chkMinutes" class="cl-checkbox" @click="chkChange"></v-checkbox>
                </v-col>

            </v-row>




        </v-form>

    </v-container>
</template>

<script>
import apiprocessi from "../utils/processi/apiprocessi";
import apiconfprocessi from "../utils/confprocessi/apiconfprocessi";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();


        if (window.reloadGridProcess != null) {
            clearInterval(window.reloadGridProcess);
        }

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/

        var pointerVue = this;


        pointerVue.loadEdit = 0;

        this.setupButtons();

        setTimeout(() => {
            this.initConfProcessi();
        }, 100);



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    setTimeout(() => {
                        this.validate();
                    }, 200);

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });



        $(document).ready(function () {


            $('body').on('blur', '#fldTitolo', function (event) {
                console.log(event);

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });

            $('body').on('blur', '#fldDescrizione', function (event) {
                console.log(event);

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });


            $('body').on('blur', '#fldNumFilesLeft', function (event) {
                console.log(event);

                console.log("IMPOSTAZIONI: ", pointerVue.impostazioni_invio_mail);

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);

            });

            $('body').on('ionChange', '.minfreq', function (event) {
                console.log(event);

                pointerVue.impostazioni_invio_mail.mail_minutes_tot = this.value;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);

            });


            $('body').on('ionChange', '.id_tipologia', function (event) {
                console.log(event);

                pointerVue.impostazioni_invio_mail.id_tipologia = this.value;

                console.log("TIPOLOGIE: ", pointerVue.tipologie);

                var code_tipologia = "";

                for (var x = 0; x < pointerVue.tipologie.length; x++) {

                    if (parseInt(pointerVue.tipologie[x].id) == parseInt(this.value)) {

                        code_tipologia = pointerVue.tipologie[x].codice;

                    }


                }

                console.log("CODE TIPO: ", code_tipologia);


                pointerVue.showRow = false;
                pointerVue.showRow2 = false;
                pointerVue.showRow3 = false;
                pointerVue.showRow4 = false;
                pointerVue.showRow5 = false;
                pointerVue.showRow6 = false;
                pointerVue.showRow7 = false;
                pointerVue.showRow8 = false;
                pointerVue.showRow10 = false;
                pointerVue.showRow11 = false;
                pointerVue.showRowMail = false;
                pointerVue.showRow12 = false;
                pointerVue.showRowTitle = false;
                pointerVue.showRowDesc = false;


                if (this.value != "") {

                    pointerVue.showRow = true;
                    pointerVue.showRowMail = true;
                }

                if (code_tipologia == "DELETEFILE") {
                    pointerVue.showRow2 = true;

                }

                if (code_tipologia == "RISORSEINPROVA") {
                    pointerVue.showRow3 = true;
                }

                if (code_tipologia == "INVOICEWEBSCADENZAFATTURE") {
                    pointerVue.showRow4 = true;
                    pointerVue.showRow5 = true;
                    pointerVue.showRow6 = true;
                    pointerVue.showRow7 = true;
                    pointerVue.showRow8 = true;
                }


                if (code_tipologia == "AUTOAPPROVAZIONEFERIE/PERMESSI") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                if (code_tipologia == "AUTOAPPROVAZIONEFERIE/PERMESSINEXT3") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                if (code_tipologia == "ALERTCOMPILAZIONETIMESHEET") {
                    pointerVue.showRow12 = true;
                    pointerVue.showRowTitle = true;
                    pointerVue.showRowDesc = true;
                }

                if (code_tipologia == "ALERTCOMPILAZIONETIMESHEETNEXT3") {
                    pointerVue.showRow12 = true;
                    pointerVue.showRowTitle = true;
                    pointerVue.showRowDesc = true;
                }

                if (code_tipologia == "DOXWEBAGGIORNAMENTOSTATOFILECUD") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                if (code_tipologia == "DOXWEBAGGIORNAMENTOSTATOFILECEDOLINI") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                if (code_tipologia == "DOXWEBSPLITCEDOLINICUD") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                if (code_tipologia == "DOMAINBOOST") {
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow = false;
                }

                setTimeout(() => {

                    pointerVue.getConfProcesso(pointerVue.impostazioni_invio_mail.id_tipologia);

                }, 200);


            });

            $('body').on('ionChange', '#dalle', function (event) {
                console.log(event);


                console.log("DALLE: ", this.value);

                var fromTime = this.value.toString().substring(11, 16);
                fromTime = fromTime + ":00";

                pointerVue.impostazioni_invio_mail.mail_minutes_form_time = fromTime;


                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });

            $('body').on('ionChange', '#alle', function (event) {
                console.log(event);

                console.log("ALLE: ", this.value);

                var toTime = this.value.toString().substring(11, 16);
                toTime = toTime + ":00";


                pointerVue.impostazioni_invio_mail.mail_minutes_to_time = toTime;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });


            $('body').on('ionChange', '#selfrequenza', function (event) {
                console.log(event);

                var v_date = new Date(this.value);

                var v_minutes = v_date.getHours() * 60;

                pointerVue.impostazioni_invio_mail.mail_minutes_tot = v_minutes;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);



            });

            $('body').on('blur', '#fldDaysLeft', function (event) {
                console.log(event);

                console.log("DAYS BEFORE: ", pointerVue.impostazioni_invio_mail.days_before_alert);

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);



            });




            $('body').on('ionChange', '#dalleExpiring', function (event) {
                console.log(event);


                console.log("DALLE: ", this.value);

                var fromTime = this.value.toString().substring(11, 16);
                fromTime = fromTime + ":00";

                pointerVue.impostazioni_invio_mail.mail_att_expiring_time_from = fromTime;


                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });

            $('body').on('ionChange', '#alleExpiring', function (event) {
                console.log(event);

                console.log("ALLE: ", this.value);

                var toTime = this.value.toString().substring(11, 16);
                toTime = toTime + ":00";


                pointerVue.impostazioni_invio_mail.mail_att_expiring_time_to = toTime;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);


            });


            $('body').on('ionChange', '#selfrequenzaexpiring', function (event) {
                console.log(event);

                var v_date = new Date(this.value);

                var v_minutes = v_date.getHours() * 60;

                pointerVue.impostazioni_invio_mail.mail_att_expiring_minute = v_minutes;

                setTimeout(() => {

                    pointerVue.saveData();

                }, 200);



            });



        });



    },

    components: {
        VueAutonumeric,
    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        showRow10: false,
        showRow11: false,
        showRow12: false,
        showRowMail: false,
        showRowTitle: false,
        showRowDesc: false,

        minuti_frequenza: [
            { valore: 1, descrizione: "1 min" },
            { valore: 5, descrizione: "5 min" },
            { valore: 10, descrizione: "10 min" },
            { valore: 15, descrizione: "15 min" },
            { valore: 20, descrizione: "20 min" },
            { valore: 25, descrizione: "25 min" },
            { valore: 30, descrizione: "30 min" },
            { valore: 35, descrizione: "35 min" },
            { valore: 40, descrizione: "40 min" },
            { valore: 45, descrizione: "45 min" },
            { valore: 50, descrizione: "50 min" },
            { valore: 55, descrizione: "55 min" },
            { valore: 60, descrizione: "1 ora" },
            { valore: 120, descrizione: "2 ore" },
            { valore: 180, descrizione: "3 ore" },
        ],


        showRow4: false,
        showRow5: false,
        showRow6: false,
        showRow7: false,
        showRow8: false,

        loadEdit: 0,

        tipologie: [],

        fieldDis: false,

        showRow: false,
        showRow2: false,
        showRow3: false,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Configurazione Processi",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni_invio_mail: {
            mail_enable_daily: "N",
            mail_enable_weekly: "N",
            id_tipologia: "",
            mail_minutes_monday: "N",
            mail_minutes_tuesday: "N",
            mail_minutes_wednesday: "N",
            mail_minutes_thursday: "N",
            mail_minutes_friday: "N",
            mail_minutes_saturday: "N",
            mail_minutes_sunday: "N",
            send_mail: "N",
            num_files_left: 0,
            days_before_alert: null,
            mail_minutes_tot: "",
            titolo: "",
            descrizione: "",
        },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {


        chkChange: function () {



            var that = this;

            if (that.loadEdit == 0) {

                setTimeout(() => {

                    that.saveData();

                }, 200);

            }



        },




        chkChangeOld: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                // var pulsantis = [{
                //     text: "Salva",
                //     icon: "mdi-content-save",
                //     link: "/timesheet",
                //     id: "btn_save",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/save-alertduedate.png",
                //     //class: "inverted",
                //     title: "Salva",
                //     width: 30
                // },

                // ];

                var pulsantis = [
                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;

            if ($("#selfrequenza").val() != "") {

                var v_date = new Date($("#selfrequenza").val());

                var v_minutes = v_date.getHours() * 60;

                that.impostazioni_invio_mail.mail_minutes_tot = v_minutes;

            }

            var fromTime = null;
            if ($("#dalle").val() != "") {

                if ($("#dalle").val().length > 8) {

                    fromTime = $("#dalle").val().toString().substring(11, 16);
                    fromTime = fromTime + ":00";
                    that.impostazioni_invio_mail.mail_minutes_form_time = fromTime;

                } else {

                    that.impostazioni_invio_mail.mail_minutes_form_time = $("#dalle").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_minutes_form_time = null;
            }

            var toTime = null;
            if ($("#alle").val() != "") {

                if ($("#alle").val().length > 8) {

                    toTime = $("#alle").val().toString().substring(11, 16);
                    toTime = toTime + ":00";
                    that.impostazioni_invio_mail.mail_minutes_to_time = toTime;

                } else {

                    that.impostazioni_invio_mail.mail_minutes_to_time = $("#alle").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_minutes_to_time = null;
            }

            // that.impostazioni_invio_mail.mail_minutes_form_time = $("#dalle").val();
            // that.impostazioni_invio_mail.mail_minutes_to_time = $("#alle").val();

            if ($("#selfrequenzaexpiring").val() != "") {

                var v_date_exp = new Date($("#selfrequenzaexpiring").val());

                var v_minutes_exp = v_date_exp.getHours() * 60;

                that.impostazioni_invio_mail.mail_att_expiring_minute = v_minutes_exp;

            }

            var fromExp = null;
            if ($("#dalleExpiring").val() != "") {

                if ($("#dalleExpiring").val().length > 8) {

                    fromExp = $("#dalleExpiring").val().toString().substring(11, 16);
                    fromExp = fromExp + ":00";
                    that.impostazioni_invio_mail.mail_att_expiring_time_from = fromExp;

                } else {

                    that.impostazioni_invio_mail.mail_att_expiring_time_from = $("#dalleExpiring").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_att_expiring_time_from = null;
            }

            var toExp = null;
            if ($("#alleExpiring").val() != "") {

                if ($("#alleExpiring").val().length > 8) {

                    toExp = $("#alleExpiring").val().toString().substring(11, 16);
                    toExp = toExp + ":00";
                    that.impostazioni_invio_mail.mail_att_expiring_time_to = toExp;

                } else {

                    that.impostazioni_invio_mail.mail_att_expiring_time_to = $("#alleExpiring").val();

                }

            } else {
                that.impostazioni_invio_mail.mail_att_expiring_time_to = null;
            }

            // validazione pagamenti scaduti
            if (that.impostazioni_invio_mail.mail_enable_minutes == "S") {

                if (that.impostazioni_invio_mail.mail_minutes_tot == "") {
                    errore = "Specificare la frequenza dei pagamenti scaduti";
                } else {
                    if (that.impostazioni_invio_mail.mail_minutes_tot == null) {
                        errore = "Specificare la frequenza dei pagamenti scaduti";
                    }
                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_form_time == "") {
                        errore = "Specificare un valore dalle ore dei pagamenti scaduti";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_form_time == null) {
                            errore = "Specificare un valore dalle ore dei pagamenti scaduti";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_to_time == "") {
                        errore = "Specificare un valore alle ore dei pagamenti scaduti";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_to_time == null) {
                            errore = "Specificare un valore alle ore dei pagamenti scaduti";
                        }
                    }

                }

            }

            // pagamenti in scadenza
            if (that.impostazioni_invio_mail.mail_att_expiring == "S") {

                if (that.impostazioni_invio_mail.mail_att_expiring_minute == "") {
                    errore = "Specificare la frequenza dei pagamenti in scadenza";
                } else {
                    if (that.impostazioni_invio_mail.mail_att_expiring_minute == null) {
                        errore = "Specificare la frequenza dei pagamenti in scadenza";
                    }
                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_att_expiring_time_from == "") {
                        errore = "Specificare un valore dalle ore dei pagamenti in scadenza";
                    } else {
                        if (that.impostazioni_invio_mail.mail_att_expiring_time_from == null) {
                            errore = "Specificare un valore dalle ore dei pagamenti in scadenza";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_att_expiring_time_to == "") {
                        errore = "Specificare un valore alle ore dei pagamenti in scadenza";
                    } else {
                        if (that.impostazioni_invio_mail.mail_att_expiring_time_to == null) {
                            errore = "Specificare un valore alle ore dei pagamenti in scadenza";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.days_left_payments_expiring > 50) {
                        errore = "Specificare un valore dei giorni rimasti alla scdenza inferiore o uguale a 50";
                    }

                }


            }

            console.log("CONF: ", that.impostazioni_invio_mail);

            if (errore == "") {

                console.log("ok");

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },


        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            if (that.loadEdit == 0) {

                console.log("TOKEN ", v_token);
                console.log("THAT ", that);

                console.log("PROCESSO: ", that.impostazioni_invio_mail);

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apiconfprocessi.setConfProcesso(
                    that.impostazioni_invio_mail,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from setConfProcesso", res);


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }



        },

        getConfProcesso: async function (id_tipologia) {


            var v_token = window.$cookies.get("token");

            var that = this;

            that.loadEdit = 1;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfprocessi.getConfProcesso(
                v_token,
                id_tipologia
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfProcesso", res);

                try {

                    if (res.data.Result != null) {

                        that.impostazioni_invio_mail = res.data.Result;

                        if (res.data.Result.mail_minutes_form_time != null) {


                            if (res.data.Result.mail_minutes_form_time != "00:00:00") {

                                $("#dalle").val(res.data.Result.mail_minutes_form_time);

                            }
                            else {

                                if (id_tipologia == "AUTOAPPROVAZIONEFERIE/PERMESSI") {


                                    $("#dalle").val("00:00");

                                }
                                else {

                                    $("#dalle").val("");

                                }


                            }



                        }

                        if (res.data.Result.mail_minutes_to_time != null) {

                            if (res.data.Result.mail_minutes_to_time != "00:00:00") {

                                $("#alle").val(res.data.Result.mail_minutes_to_time);

                            }
                            else {

                                $("#alle").val("");

                            }




                        }

                        if (res.data.Result.mail_minutes_tot != null) {

                            var v_valore = res.data.Result.mail_minutes_tot / 60;

                            var strDay = v_valore.toString().padStart(2, "0");

                            console.log("DAY: ", strDay);

                            var v_data = "2022-03-29T" + strDay + ":21:23.654+02:00";

                            $("#selfrequenza").val(v_data);

                        }

                        if (res.data.Result.mail_att_expiring_time_from != null) {

                            $("#dalleExpiring").val(res.data.Result.mail_att_expiring_time_from);

                        }

                        if (res.data.Result.mail_att_expiring_time_to != null) {

                            $("#alleExpiring").val(res.data.Result.mail_att_expiring_time_to);

                        }

                        if (res.data.Result.mail_att_expiring_minute != null) {

                            v_valore = res.data.Result.mail_att_expiring_minute / 60;

                            strDay = v_valore.toString().padStart(2, "0");

                            console.log("DAY: ", strDay);

                            v_data = "2022-03-29T" + strDay + ":21:23.654+02:00";

                            $("#selfrequenzaexpiring").val(v_data);

                        }






                    }
                    else {

                        setTimeout(() => {

                            $("#dalle").val("");
                            $("#alle").val("");
                            $("#selfrequenza").val("");

                            that.impostazioni_invio_mail.mail_minutes_monday = "N";
                            that.impostazioni_invio_mail.mail_minutes_tuesday = "N";
                            that.impostazioni_invio_mail.mail_minutes_wednesday = "N";
                            that.impostazioni_invio_mail.mail_minutes_thursday = "N";
                            that.impostazioni_invio_mail.mail_minutes_friday = "N";
                            that.impostazioni_invio_mail.mail_minutes_saturday = "N";
                            that.impostazioni_invio_mail.mail_minutes_sunday = "N";
                            that.impostazioni_invio_mail.send_mail = "N";

                        }, 300);





                    }

                    that.loadEdit = 0;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        initConfProcessi: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprocessi.initInsert(
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                that.tipologie = res.data.Tipologia;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }









    }

})
</script>

<style>
.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png") !important;
    background-size: 34px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png") !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

#fldDaysLeft {
    margin-left: -10px;
}

.confInvioMailAlert .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    margin-left: -10px;
}




.confInvioMailAlert .cl-checkbox i {
    font-size: 13px !important;
}


.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}



.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
