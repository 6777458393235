import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIEasyMonitor {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async saveServer(server, id) {

    let formData = new FormData();

    formData.append("v_id", id);

    for (var key in server) {
      formData.append(key, server[key]);
    }

    return axios.post(this.getUrlDomain("SchedtoolSaveServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editServer(id, username) {

    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("username", username);

    return axios.post(this.getUrlDomain("SchedtoolEditServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteServer(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("SchedtoolDeleteServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertCheckServer(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EasyMonitorInitInsertCheckServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveCheckServer(check_server, id) {

    let formData = new FormData();

    formData.append("v_id", id);

    for (var key in check_server) {
      formData.append(key, check_server[key]);
    }

    return axios.post(this.getUrlDomain("EasyMonitorSaveCheckServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCheckServer(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EasyMonitorDeleteCheckServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async doCheckServer(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EasyMonitorDoCheck"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async doCheckServerInTable(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EasyMonitorDoCheckInTable"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async changeStatusCheckServer(id, v_status) {

    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_status", v_status);

    return axios.post(this.getUrlDomain("EasyMonitorChgStatusCheckServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertServer(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("SchedtoolInitInsertServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initFilterServer(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("SchedtoolInitFilterServer"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getRefresh(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EasyMonitorGetRefresh"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveRefresh(refresh) {

    let formData = new FormData();

    for (var key in refresh) {
      formData.append(key, refresh[key]);
    }

    return axios.post(this.getUrlDomain("EasyMonitorSaveRefresh"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getServers(v_token) {

    let formData = new FormData();

    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomain("SchedtoolGetServers"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIEasyMonitor({
  url: "https://services.ebadge.it/public/api/"
})
