<template>
    <v-container style="max-width:500px;">

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">



                    <v-text-field v-model="valEndPoint" label="End Point" readonly="true"></v-text-field>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">



                    <v-text-field v-model="valPublicKey" label="Public Key" readonly="true"></v-text-field>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">



                    <v-text-field v-model="valToken" label="Token" readonly="true"></v-text-field>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="padding-top:60px">

                    <div style="
        background-color: rgb(238, 120, 29);
        color: white;
        font-weight: bold;
        padding: 4px;
        font-size: 14px;">Notifications</div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_alertduedate.png"
                        max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                        transition="scale-transition" title="Annulla" @click="subscribeUser" />

                </v-col>

            </v-row>






        </v-form>

    </v-container>
</template>

<script>

import apinotification from "../utils/notification/apinotification";


import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        this.setupButtons();


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/parenti"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({

        valEndPoint: "",
        valPublicKey: "",
        valToken: "",



        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        parente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {


        saveRegistration: async function (product, endpoint, publickey, token) {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apinotification.saveRegistration(
                v_token,
                product,
                endpoint,
                publickey,
                token
            ).then((res) => {

                console.log("res from saveRegistration", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        initRegistration: async function () {

            var that = this;


            var response = await apinotification.initRegistration(
                0
            ).then((res) => {

                console.log(res);

                // console.log("res from initRegistration", res);

                // window.keyCode = atob(res.data.Result);

                // alert(window.keyCode);


                that.registerUser();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        btnCancelSearch: function () {

            console.log("ok");
        },


        getUrlScript: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = "/scripts/service-worker.js";
            }

            if (location.hostname != "localhost") {

                result = "/schedtool/scripts/service-worker.js";

            }

            return result;

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        subscribeUser: function () {


            this.registerUser();


        },


        registerUser: function () {


            var that = this;

            alert(that.getUrlScript());

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register(that.getUrlScript())
                    .then(function (registration) {
                        console.log('Service Worker registered with scope:', registration.scope);


                        alert('Service Worker registered with scope:' + registration.scope);

                        Notification.requestPermission().then(function (permission) {
                            if (permission === 'granted') {
                                that.subscribeUserToPush(registration);
                            }
                            else {

                                that.$swal({
                                    icon: "error",
                                    text: "Notifiche non permesse",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                            }
                        });
                    });
            }


        },



        arrayBufferToBase64: function (buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },



        subscribeUserToPush: function (registration) {

            var that = this;


            const applicationServerKey = this.urlB64ToUint8Array('BOSsMJaAgY7z9C8MsxRqf-wa7JTx_rE7E0o6i9snxoCjGASxBh_jr-0NU34tkJS1OoMzdDZzDsXIClNLekQ9oJ0');
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
                .then(function (subscription) {



                    console.log('User is subscribed:', subscription);


                    alert('User is subscribed: ' + subscription);

                    const subscriptionData = {
                        endpoint: subscription.endpoint,
                        keys: {
                            p256dh: that.arrayBufferToBase64(subscription.getKey('p256dh')),
                            auth: that.arrayBufferToBase64(subscription.getKey('auth'))
                        }
                    };

                    console.log(subscriptionData);

                    that.valEndPoint = subscription.endpoint;
                    that.valPublicKey = that.arrayBufferToBase64(subscription.getKey('p256dh'));
                    that.valToken = that.arrayBufferToBase64(subscription.getKey('auth'));

                    that.saveRegistration("schedtool",
                        subscription.endpoint,
                        that.arrayBufferToBase64(subscription.getKey('p256dh')),
                        that.arrayBufferToBase64(subscription.getKey('auth')));

                    // var strparams = "ENDPOINT: " + subscription.endpoint;
                    // strparams = strparams + "\n\nPUBLIC KEY: " + that.arrayBufferToBase64(subscription.getKey('p256dh'));
                    // strparams = strparams + "\n\nTOKEN: " + that.arrayBufferToBase64(subscription.getKey('auth'));


                    // alert(strparams);



                    // console.log("Registration Data:", subscriptionData);


                    // fetch(that.getUrlDomain("subscribeUser"), {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     body: JSON.stringify(subscription)
                    // });



                })
                .catch(function (error) {
                    console.error('Failed to subscribe the user:', error);
                });


        },


        urlB64ToUint8Array: function (base64String) {

            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;



        },



        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },







    }

})
</script>