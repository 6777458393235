import axios from 'axios'


class APIConfProcessi {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async setConfProcesso(impostazioni_invio_mail, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);

    for ( var key in impostazioni_invio_mail ) {
       formData.append(key, impostazioni_invio_mail[key]);
    }

    return axios.post(this.getUrlDomain("SchedToolSaveConfProcesso") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getConfProcesso(user, id_tipologia)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
    formData.append("v_id_tipologia",  id_tipologia);

    return axios.post(this.getUrlDomain("SchedToolGetConfProcesso") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }
  




}

export default new APIConfProcessi({
  url: "http://emtool.local/public/api/alertduedate/"
})
