import axios from 'axios'

class APINotification {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async initRegistration(v_id)
  {


    let formData = new FormData();

    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("initRegistration") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveRegistration(login, product, endpoint, publickey, token)
  {


    let formData = new FormData();

    formData.append("login",  login);
    formData.append("product",  product);
    formData.append("endpoint",  endpoint);
    formData.append("publickey",  publickey);
    formData.append("token",  token);

    return axios.post(this.getUrlDomain("saveRegistrationUser") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async checkRegistration(login, product, endpoint, publickey, token)
  {


    let formData = new FormData();

    formData.append("login",  login);
    formData.append("product",  product);
    formData.append("endpoint",  endpoint);
    formData.append("publickey",  publickey);
    formData.append("token",  token);

    return axios.post(this.getUrlDomain("checkRegistrationUser") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async registrationDisable(login, product, endpoint, publickey, token)
  {


    let formData = new FormData();

    formData.append("login",  login);
    formData.append("product",  product);
    formData.append("endpoint",  endpoint);
    formData.append("publickey",  publickey);
    formData.append("token",  token);

    return axios.post(this.getUrlDomain("registrationUserDisable") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async registrationEnable(login, product, endpoint, publickey, token)
  {


    let formData = new FormData();

    formData.append("login",  login);
    formData.append("product",  product);
    formData.append("endpoint",  endpoint);
    formData.append("publickey",  publickey);
    formData.append("token",  token);

    return axios.post(this.getUrlDomain("registrationUserEnable") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getUtentiNotification(product)
  {


    let formData = new FormData();

    formData.append("product",  product);

    return axios.post(this.getUrlDomain("getUtentiNotification") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async sendNotification(prodotto, utente, titolo, testo, sender, icon, image, badge, tag, renotify, url)
  {


    let formData = new FormData();

    formData.append("utente",  utente);
    formData.append("titolo",  titolo);
    formData.append("testo",  testo);
    formData.append("prodotto",  prodotto);
    formData.append("sender",  sender);
    formData.append("icon",  icon);
    formData.append("image",  image);
    formData.append("badge",  badge);
    formData.append("tag",  tag);
    formData.append("renotify",  renotify);
    formData.append("url",  url);
  
    return axios.post(this.getUrlDomain("sendNotification") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteRowsNotification(v_id)
  {


    let formData = new FormData();

    formData.append("v_id",  v_id);
  
    return axios.post(this.getUrlDomain("deleteRowsNotification") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APINotification({
  url: "http://emtool.local/public/api/alertduedate/"
})
